import React from 'react';
import * as styles from './css/style.module.scss';

export const Fab = (props) => {
    const {linkUrl, display} = props;
    return (<a className={`${styles.wrap} ${display ? styles.show : ''}`} target='_blank' rel="noreferrer" href={linkUrl}>
        <div className={styles.button}>
            <div className={styles.button__wrap}>
                <svg width="100%" height="100%" viewBox="0 0 103 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M44.969 81.9379C65.3864 81.9379 81.9379 65.3864 81.9379 44.969C81.9379 24.5516 65.3864 8 44.969 8C24.5516 8 8 24.5516 8 44.969C8 65.3864 24.5516 81.9379 44.969 81.9379ZM44.969 89.9379C69.8046 89.9379 89.9379 69.8046 89.9379 44.969C89.9379 20.1333 69.8046 0 44.969 0C20.1333 0 0 20.1333 0 44.969C0 69.8046 20.1333 89.9379 44.969 89.9379Z" fill="#002649"/>
                    <rect x="78.6562" y="68.5" width="33.7153" height="8" rx="2" transform="rotate(45 78.6562 68.5)" fill="#002649"/>
                </svg>
            </div>
            <div className={styles.button__img}>
                <svg width="100%" height="100%" viewBox="0 0 46 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M33.2593 0.404132C29.8109 0.404132 26.6729 1.4326 24.4444 3.30431C24.1405 3.55429 23.8526 3.82307 23.5824 4.10898C23.542 4.15266 23.493 4.18746 23.4385 4.2113C23.384 4.23514 23.3251 4.24744 23.2656 4.24744C23.206 4.24744 23.1471 4.23514 23.0926 4.2113C23.0381 4.18746 22.9891 4.15266 22.9487 4.10898C20.4099 1.47137 16.7029 0.39993 13.3235 0.39993C12.1965 0.397852 11.0724 0.514701 9.97003 0.748481C9.9038 0.763862 9.84221 0.794688 9.79019 0.838402C9.73816 0.882116 9.69723 0.937443 9.67075 0.999967C9.64426 1.06249 9.63298 1.13038 9.63781 1.19809C9.64264 1.2658 9.66344 1.33134 9.69853 1.38949L12.1425 5.47293C12.184 5.54165 12.244 5.59737 12.3156 5.63387C12.3872 5.67036 12.4676 5.68617 12.5478 5.67946C12.7978 5.65794 13.0563 5.645 13.3235 5.645C17.9141 5.645 20.4401 8.36869 20.4401 13.3083V28.2132C20.4401 28.3273 20.4855 28.4367 20.5663 28.5174C20.6472 28.5981 20.7568 28.6435 20.8711 28.6435H25.7031C25.8174 28.6435 25.927 28.5981 26.0079 28.5174C26.0887 28.4367 26.1341 28.3273 26.1341 28.2132V13.3083C26.1341 8.36869 28.6601 5.645 33.2506 5.645C37.8412 5.645 40.3671 8.36869 40.3671 13.3083V28.2132C40.3671 28.3273 40.4125 28.4367 40.4934 28.5174C40.5742 28.5981 40.6839 28.6435 40.7982 28.6435H45.5698C45.6841 28.6435 45.7938 28.5981 45.8746 28.5174C45.9554 28.4367 46.0008 28.3273 46.0008 28.2132V12.7532C46.0095 3.63991 39.143 0.404132 33.2593 0.404132Z" fill="#002649"/>
                    <path d="M6.75044 6.45823C6.75044 4.6712 5.2992 3.22253 3.50901 3.22253C1.71882 3.22253 0.267578 4.6712 0.267578 6.45823C0.267578 8.24525 1.71882 9.69393 3.50901 9.69393C5.2992 9.69393 6.75044 8.24525 6.75044 6.45823Z" fill="#002649"/>
                    <path d="M5.78511 13.6654H1.00917C0.771109 13.6654 0.578125 13.858 0.578125 14.0957V28.2131C0.578125 28.4508 0.771109 28.6434 1.00917 28.6434H5.78511C6.02316 28.6434 6.21615 28.4508 6.21615 28.2131V14.0957C6.21615 13.858 6.02316 13.6654 5.78511 13.6654Z" fill="#002649"/>
                </svg>
            </div>
        </div>
        <div className={styles.text}>WE’RE <br/>HIRING!</div>
    </a>)
}
