import React from 'react';
import * as styles from './css/style.module.scss';
import arrowImg from './img/arrow.svg'
import {SpTitle} from "../SpTitle";
import dayjs from "dayjs";

export const Section6News = (props) => {
    const {newsData} = props;
    return <div className={styles.wrap}>
        <div className={styles.SpTitle}><SpTitle titleText={'NEWS'} iconColor={['#7D4698', '#A87BC1', '#D2B0EA']}
                                                 clipPathId={'sectionTitle'}/></div>
        <div className={styles.title}>
            NEWS
        </div>
        <div>
            {newsData.slice(0, 2).map((d, index) => <div className={styles.list__row} key={`${d.date}_${index}`}>
                <div className={styles.list__date}>{dayjs(d.date).format('YYYY.MM.DD')}</div>
                <div className={styles.list__title}>{d.title}</div>
                <div className={styles.list__text} dangerouslySetInnerHTML={{
                    __html: d.body.map(d => {
                        if (d.link !== null) {
                            return `<a href="${d.link.url}" target="_blank" rel="noopener noreferrer">${d.content}</a>`;
                        }
                        return d.content
                    }).join('')
                }}/>
        </div>)}
        </div>
        <div className={styles.listMore}>
            <a href="/news">もっと見る<div className={styles.listMore__image}><img src={arrowImg} alt=""/></div></a>
        </div>
    </div>
}
