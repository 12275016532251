import React from 'react';
import * as styles from './css/style.module.scss';
import logoImg from './img/logo.svg';
import ismsLogoImg from './img/isms_logo.png';

export const Footer = (props) => {
    const {pressFunc} = props;
    return <footer className={styles.footer}>
        <div className={styles.main}>
            <div className={styles.logo}>
                <img src={logoImg} alt=""/>
            </div>

            <div className={styles.contentsWrap}>
                <div className={styles.contents}>
                    <button onClick={() => pressFunc(0)}>TOP</button>
                    <button onClick={() => pressFunc(1)}>OUR MISSION</button>
                    <button onClick={() => pressFunc(2)}>SERVICE</button>
                    <button onClick={() => pressFunc(3)}>TEAM</button>
                </div>

                <div className={styles.contents}>
                    <button onClick={() => pressFunc(4)}>RECRUITING</button>
                    <button onClick={() => pressFunc(5)}>NEWS</button>
                    <button onClick={() => pressFunc(6)}>COMPANY</button>
                    <button onClick={() => pressFunc(7)}>CONTACT</button>
                </div>
            </div>

            <div className={styles.isms}>
                <div className={styles.isms__image}>
                    <img src={ismsLogoImg} alt="ISMS"/>
                </div>
                <div className={styles.isms__text}>ISO/IEC 27001:201</div>
            </div>
        </div>

        <div className={styles.copy}>
            Copyright © {`${new Date().getFullYear()}`} mento Inc. All rights reserved.
        </div>
    </footer>
}
