import React from 'react';
import * as styles from './css/style.module.scss';

export const SpMenu = (props) => {
    const {display, pressFunc} = props;

    return (
        <div className={`${styles.wrap} ${display ? styles.active : ''}`}>
            <div className={styles.inner}>
                <button onClick={() => pressFunc(0)}>TOP</button>
                <button onClick={() => pressFunc(1)}>OUR MISSION</button>
                <button onClick={() => pressFunc(2)}>SERVICE</button>
                <button onClick={() => pressFunc(3)}>TEAM</button>
                <button onClick={() => pressFunc(4)}>RECRUITING</button>
                <button onClick={() => pressFunc(5)}>NEWS</button>
                <button onClick={() => pressFunc(6)}>COMPANY</button>
                <button onClick={() => pressFunc(7)}>CONTACT</button>
            </div>
        </div>
    )
}
