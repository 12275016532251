import React, {useEffect, useRef, useState} from 'react';
import * as styles from './css/style.module.scss';
import mainImage from './img/main_image.jpg';
import mainImageSp from './img/main_image_sp.jpg';

export const Section2OurMission = (props) => {

    const {yPos} = props;
    const fadeIn_1Ref = useRef(null);
    const [fadeIn_1show, setFadeIn_1show] = useState(false);

    useEffect(() => {
        const pos1 = fadeIn_1Ref.current.getBoundingClientRect().top + yPos

        if (yPos > pos1 - window.innerHeight + (fadeIn_1Ref.current.getBoundingClientRect().top * 0.4)) {
            setFadeIn_1show(true)
        }

    }, [yPos])

    return <div className={styles.wrap}>
        <div className={styles.mainImg}>
            <img src={mainImage} alt=""/>
        </div>
        <div className={styles.mainImgSp}>
            <img src={mainImageSp} alt=""/>
        </div>
        <div className={`${styles.mission} fadeIn ${fadeIn_1show ? 'show' : ''}`} ref={fadeIn_1Ref}>
            <div className={styles.mission__header}>OUR MISSION</div>
            <div className={styles.mission__title}>
                コーチングとテクノロジーの力によって<br/>
                日本の主観的ウェルビーイングを世界No.1に</div>
            <div className={styles.mission__text}>誰もが自分の人生を主体的に生きられる社会を、<br/>テクノロジーで再現性高く実現することが私たちの使命です。</div>
        </div>
    </div>
}
