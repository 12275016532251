import React, {useEffect, useRef, useState} from 'react';
import * as styles from './css/style.module.scss';

// type Section4CardProps = {
//     position: string,
//     name: string,
//     twitterUrl: string,
//     youtrustUrl: string,
//     noteUrl: string,
//     imgSrc: string,
//     notionUrl: string,
//     display: boolean,
// }

export const Section4Card = (props) => {
    const {
        position,
        name,
        twitterUrl,
        youtrustUrl,
        noteUrl,
        imgSrc,
        notionUrl,
        pageYPos,
    } = props;

    const [showFlag, setShowFlag] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const pos = ref.current.getBoundingClientRect().top + pageYPos
        if (pageYPos > pos - window.innerHeight + (ref.current.getBoundingClientRect().top * 0.4)) {
            setShowFlag(true)
        }

    }, [pageYPos])

    return <div className={`${styles.card} fadeIn ${showFlag ? 'show' : ''}`} ref={ref} >
        <a className={styles.cardImage} target='_blank'
           href={notionUrl} rel="noreferrer">
            <div className={styles.link__hover}>
                <div className={styles.link__hoverImg}>
                    <svg width="100%" height="100%" viewBox="0 0 102 75" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M50 17C37.318 17 27 27.318 27 40C27 43.73 27.9118 47.4169 29.6425 50.7026L27.1459 60.016C27.0232 60.4735 27.1525 60.9609 27.4907 61.2958C27.8256 61.6307 28.313 61.7633 28.7705 61.6406L37.4937 59.3032C41.2204 61.7235 45.5339 63 50 63C62.682 63 73 52.682 73 40C73 27.318 62.6853 17 50 17ZM39.861 42.5894C38.432 42.5894 37.2749 41.4323 37.2749 40.0033C37.2749 38.5743 38.432 37.4172 39.861 37.4172C41.29 37.4172 42.4472 38.5743 42.4472 40.0033C42.4472 41.4323 41.29 42.5894 39.861 42.5894ZM50 42.5894C48.571 42.5894 47.4139 41.4323 47.4139 40.0033C47.4139 38.5743 48.571 37.4172 50 37.4172C51.429 37.4172 52.5861 38.5743 52.5861 40.0033C52.5861 41.4323 51.429 42.5894 50 42.5894ZM60.139 42.5894C58.71 42.5894 57.5528 41.4323 57.5528 40.0033C57.5528 38.5743 58.71 37.4172 60.139 37.4172C61.568 37.4172 62.7251 38.5743 62.7251 40.0033C62.7251 41.4323 61.568 42.5894 60.139 42.5894Z"
                            fill="white"/>
                        <path
                            d="M12.7943 67.7782C8.90519 68.9857 7.80056 70.243 6.7891 74.7696C6.77484 74.8348 6.73837 74.8933 6.68577 74.9352C6.63316 74.9771 6.5676 75 6.5 75C6.4324 75 6.36684 74.9771 6.31423 74.9352C6.26163 74.8933 6.22516 74.8348 6.2109 74.7696C5.19944 70.243 4.09481 68.9857 0.205683 67.7782C0.145967 67.7593 0.093863 67.7222 0.056893 67.6722C0.0199231 67.6222 0 67.5619 0 67.5C0 67.4381 0.0199231 67.3778 0.056893 67.3278C0.093863 67.2778 0.145967 67.2407 0.205683 67.2218C4.09481 66.0143 5.19944 64.7585 6.2109 60.2304C6.22516 60.1652 6.26163 60.1067 6.31423 60.0648C6.36684 60.0229 6.4324 60 6.5 60C6.5676 60 6.63316 60.0229 6.68577 60.0648C6.73837 60.1067 6.77484 60.1652 6.7891 60.2304C7.80056 64.757 8.90519 66.0143 12.7943 67.2218C12.854 67.2407 12.9061 67.2778 12.9431 67.3278C12.9801 67.3778 13 67.4381 13 67.5C13 67.5619 12.9801 67.6222 12.9431 67.6722C12.9061 67.7222 12.854 67.7593 12.7943 67.7782V67.7782Z"
                            fill="white"/>
                        <path
                            d="M89.7785 8.29671C85.5902 9.58479 84.4006 10.9258 83.3113 15.7542C83.296 15.8238 83.2567 15.8862 83.2001 15.9309C83.1434 15.9756 83.0728 16 83 16C82.9272 16 82.8566 15.9756 82.7999 15.9309C82.7433 15.8862 82.704 15.8238 82.6887 15.7542C81.5994 10.9258 80.4098 9.58479 76.2215 8.29671C76.1572 8.2766 76.1011 8.23702 76.0613 8.18369C76.0215 8.13036 76 8.06603 76 8C76 7.93397 76.0215 7.86964 76.0613 7.81631C76.1011 7.76298 76.1572 7.7234 76.2215 7.70329C80.4098 6.41521 81.5994 5.07572 82.6887 0.2458C82.704 0.176198 82.7433 0.113842 82.7999 0.0691179C82.8566 0.0243935 82.9272 0 83 0C83.0728 0 83.1434 0.0243935 83.2001 0.0691179C83.2567 0.113842 83.296 0.176198 83.3113 0.2458C84.4006 5.07417 85.5902 6.41521 89.7785 7.70329C89.8428 7.7234 89.8989 7.76298 89.9387 7.81631C89.9785 7.86964 90 7.93397 90 8C90 8.06603 89.9785 8.13036 89.9387 8.18369C89.8989 8.23702 89.8428 8.2766 89.7785 8.29671V8.29671Z"
                            fill="white"/>
                        <path
                            d="M101.842 21.704C98.8501 22.5895 98.0004 23.5115 97.2224 26.831C97.2114 26.8789 97.1834 26.9217 97.1429 26.9525C97.1024 26.9832 97.052 27 97 27C96.948 27 96.8976 26.9832 96.8571 26.9525C96.8166 26.9217 96.7886 26.8789 96.7776 26.831C95.9996 23.5115 95.1499 22.5895 92.1582 21.704C92.1123 21.6902 92.0722 21.663 92.0438 21.6263C92.0153 21.5896 92 21.5454 92 21.5C92 21.4546 92.0153 21.4104 92.0438 21.3737C92.0722 21.337 92.1123 21.3098 92.1582 21.296C95.1499 20.4105 95.9996 19.4896 96.7776 16.169C96.7886 16.1211 96.8166 16.0783 96.8571 16.0475C96.8976 16.0168 96.948 16 97 16C97.052 16 97.1024 16.0168 97.1429 16.0475C97.1834 16.0783 97.2114 16.1211 97.2224 16.169C98.0004 19.4885 98.8501 20.4105 101.842 21.296C101.888 21.3098 101.928 21.337 101.956 21.3737C101.985 21.4104 102 21.4546 102 21.5C102 21.5454 101.985 21.5896 101.956 21.6263C101.928 21.663 101.888 21.6902 101.842 21.704V21.704Z"
                            fill="white"/>
                    </svg>
                </div>

                <div className={styles.link__hoverText}>
                    TALK WITH ME?
                </div>
            </div>
            <img src={imgSrc} alt=""/>
        </a>
        <div className={styles.cardPosition}>{position}</div>
        <div className={styles.cardName}>{name}</div>
        <div className={styles.cardLinkList}>

            {twitterUrl &&
                <a href={twitterUrl} target='_blank' rel="noreferrer">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7349 8.46857L19.0755 0H17.336L10.9623 7.35325L5.87157 0H0L7.69813 11.1193L0 20H1.73963L8.47059 12.2348L13.8467 20H19.7183L11.7348 8.46857H11.7353H11.7349ZM9.35232 11.2172L8.5723 10.11L2.36634 1.29961H5.03815L10.0466 8.40986L10.8266 9.51703L17.3368 18.7593H14.665L9.35248 11.2175V11.217L9.35232 11.2172Z" fill="white"/>
                    </svg>
            </a>}

            {youtrustUrl &&
                <a href={youtrustUrl} target='_blank' rel="noreferrer">
                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="path"
                          d="M0.705078 9.4047C0.705078 9.4047 1.66468 9.55007 2.50698 9.17872C3.34928 8.80737 3.95763 8.22127 4.50635 7.67415C5.05507 7.12703 9.91201 2.28692 10.1002 2.08208C10.1917 1.98164 12.0181 0.46846 14.1427 0.192919C16.7823 -0.148698 18.0023 0.740041 19.0798 1.40411C20.1574 2.06818 21.5849 3.86682 22.4106 5.72159C23.3947 7.9312 23.2655 9.67627 23.292 10.8392C22.6551 10.8604 22.0063 10.9601 21.4112 11.196C20.9235 11.389 20.5458 11.728 20.1601 12.0709C19.7744 12.4138 19.4125 12.7488 19.0447 13.0944C18.7505 13.37 18.4576 13.6468 18.1713 13.931C18.1275 13.9746 18.0838 14.0175 18.0387 14.0631C17.7644 14.3565 14.5927 17.5401 14.3939 17.7159C14.1951 17.8916 13.5224 18.7011 12.2189 19.3764C11.2248 19.8924 10.2401 20.197 8.79072 20.1581C7.34139 20.1191 6.23534 19.6862 5.67601 19.3962C4.50171 18.7869 3.26643 17.4423 2.46323 16.2119C1.66004 14.9816 1.09211 13.2821 0.915829 12.4026C0.73955 11.5231 0.705078 10.7632 0.705078 9.4047Z"
                          fill="white"/>
                </svg>
            </a>}
            {noteUrl &&
            <a href={noteUrl} target='_blank' rel="noreferrer">
                <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="path" fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.61232 7.65345V6.06552C7.61232 5.77614 7.62731 5.67964 7.67186 5.53118C7.79093 5.12309 8.19295 4.81891 8.66922 4.81891C9.1455 4.81891 9.54739 5.1305 9.66646 5.53118C9.71114 5.67964 9.72612 5.77614 9.72612 6.06552V8.51417C9.72612 8.66263 9.72612 8.81095 9.69629 8.9446C9.61448 9.31554 9.27955 9.6496 8.90736 9.73116C8.77344 9.76078 8.62454 9.76078 8.47564 9.76078H6.01961C5.72937 9.76078 5.63257 9.74597 5.48367 9.70142C5.08178 9.58271 4.76926 9.18202 4.76926 8.70718C4.76926 8.2322 5.08178 7.83151 5.48367 7.7128C5.63257 7.66825 5.72937 7.65345 6.01961 7.65345H7.61232ZM16.3649 18.7544H3.1914V7.186C3.1914 7.03755 3.24351 6.91143 3.34773 6.80753L6.76386 3.40151C6.86808 3.29774 6.99457 3.24578 7.14347 3.24578H16.3649V18.7544ZM17.861 0.633733C17.794 0.62633 17.7047 0.618927 17.5335 0.618927H6.79356C6.67462 0.618927 6.55555 0.62633 6.47361 0.633733C5.98235 0.678283 5.54321 0.908303 5.19343 1.25704L1.19667 5.2418C0.84702 5.59067 0.616175 6.02837 0.571624 6.51815C0.564066 6.59972 0.556641 6.71843 0.556641 6.83714V19.9193C0.556641 20.09 0.564066 20.1791 0.571624 20.2459C0.631026 20.8024 1.13713 21.3069 1.69535 21.3662C1.76244 21.3738 1.85168 21.3812 2.02286 21.3812H17.5335C17.7047 21.3812 17.794 21.3738 17.861 21.3662C18.4192 21.3069 18.9253 20.8024 18.9848 20.2459C18.9921 20.1791 18.9997 20.09 18.9997 19.9193V2.08074C18.9997 1.91008 18.9921 1.82098 18.9848 1.75422C18.9253 1.19768 18.4192 0.693088 17.861 0.633733Z"
                          fill="white"/>
                </svg>
            </a>}
        </div>
    </div>
}
