import React from 'react';
import * as styles from './css/style.module.scss';
import bg from './img/bg.svg';
import iccImg from './img/icc.png';
import f24Img from './img/f24_start_catapult.png';

export const Section8Contact = (props) => {

    return <div className={styles.wrap}>
        <div className={styles.imgWrap}>
            <img className={styles.imgWrap__iic} src={iccImg} alt=""/>
            <img className={styles.imgWrap__f24} src={f24Img} alt=""/>
        </div>
        <div className={styles.bg}>
            <img src={bg} alt=""/>
        </div>

        <a className={styles.linkWrap} href='https://form.run/@mentoinc' rel="noreferrer" target='_blank'>
            <div className={styles.linkTitle}>CONTACT</div>
            <div className={styles.linkText}>mentoのサービスに関するお問い合わせや<br/>
                取材依頼は、以下よりお気軽にご連絡ください。</div>
            <div  className={styles.linkButton}>お問い合わせ</div>
        </a>
    </div>
}
