import React from 'react';
import * as styles from './css/style.module.scss';
import memberImg from './img/member.png'
import memberImgSp from './img/member_sp.png'

export const Section5Recruiting = () => {
    return <div className={styles.wrap}>
        <div className={styles.mainImg}>
            <img src={memberImg} alt=""/>
        </div>

        <div className={styles.mainImgSp}>
            <img src={memberImgSp} alt=""/>
        </div>

        <a className={styles.linkWrap} href='https://recruit.mento.co.jp/' rel="noreferrer" target='_blank'>
            <div className={styles.linkTitle}>RECRUITING</div>
            <div className={styles.linkText}>mentoでは一緒に事業を作ってくれる仲間を<br/>
                さまざまな職種で募集中です。</div>
            <div className={styles.linkButton}>採用情報を見る</div>
        </a>
    </div>
}
