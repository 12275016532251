import * as React from "react"
import "normalize.css"
// import {Helmet} from "react-helmet";
import {graphql} from "gatsby"
import {Opening} from "../components/Opening";
// import {MainWrap} from "../components/MainWrap";
import {Head} from "../components/Head";

const IndexPage = ({data}) => {
    return <div>
        <Head data={data} pageData={{}}/>
        <Opening newsData={data.news.data.sort((a, b) => new Date(b.date) - new Date(a.date))}/>
    </div>;
}

// markup


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        news {
            id
            data {
                date
                title
                body {
                    content
                    link {
                        url
                    }
                }
            }
        }
    }
`

export default IndexPage
