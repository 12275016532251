// extracted by mini-css-extract-plugin
export var bounce = "style-module--bounce--ZYNaC";
export var clipText = "style-module--clipText--hcn-D";
export var inner = "style-module--inner--Ou+fZ";
export var mainText = "style-module--mainText--u0QX-";
export var mainTitle1 = "style-module--mainTitle1--HJmf+";
export var mainTitle1__clip = "style-module--mainTitle1__clip---0jaG";
export var mainTitle1__clip2 = "style-module--mainTitle1__clip2--E5HDx";
export var scrollIndicator = "style-module--scrollIndicator--mX5k4";
export var show = "style-module--show--FlpPC";
export var spBr = "style-module--spBr--zRD+z";
export var whiteImg = "style-module--whiteImg--CBuEL";
export var wrap = "style-module--wrap--Mvbc6";