import * as React from 'react';
import * as styles from './css/style.module.scss';
import {Header} from "../Header";
import {Section1Top} from "../Section1_top";
import {Section2OurMission} from "../Section2_ourMission";
import {Section3Service} from "../Section3_service";
import {Section4Team} from "../Section4_team";
import {Section5Recruiting} from "../Section5_recruiting";
import {Section6News} from "../Section6_news";
import {Section7Company} from "../Section7_company";
import {Section8Contact} from "../Section8_contact";
import {Footer} from "../Footer";
import {SideMenu} from "../SideMenu";
import {useViewportScroll} from "framer-motion";
import {Fab} from "../FAB";
import {useEffect, useRef, useState} from "react";
import {SpMenu} from "../SpMenu";

/* props
themeColor: string,
displayFlag; boolean
 */
export const MainWrap = (props) => {
    const {themeColor, display, newsData} = props;

    const {scrollY} = useViewportScroll()
    const [yPos, setYPos] = useState(0)

    const [nowPos, setNowPos] = useState(0)

    const sec1Ref = useRef(null);
    const sec2Ref = useRef(null);
    const sec3Ref = useRef(null);
    const sec4Ref = useRef(null);
    const sec5Ref = useRef(null);
    const sec6Ref = useRef(null);
    const sec7Ref = useRef(null);
    const sec8Ref = useRef(null);

    useEffect(() => {
        scrollY.onChange((latest) => {
            setYPos(latest)

            if (nowPos !== '0' && sec1Ref && sec1Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(0);
                return;
            }

            if (nowPos !== 1 && sec2Ref && sec2Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(1);
                return;
            }

            if (nowPos !== 2 && sec3Ref && sec3Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(2);
                return;
            }

            if (nowPos !== 3 && sec4Ref && sec4Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(3);
                return;
            }

            if (nowPos !== 4 && sec5Ref && sec5Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(4);
                return;
            }

            if (nowPos !== 5 && sec6Ref && sec6Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(5);
                return;
            }

            if (nowPos !== 6 && sec7Ref && sec7Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(6);
                return;
            }

            if (nowPos !== 7 && sec8Ref && sec8Ref.current.getBoundingClientRect().bottom - (window.outerHeight / 2) > 0) {
                setNowPos(7);
                return;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [spMenuActive, setSpMenuActive] = useState(false);

    return (
        <>
            <Header spMenuDisplay={spMenuActive} spMenuDisplayFunc={(flag) => setSpMenuActive(flag)}/>
            <div className={`${styles.wrap} ${display ? styles.active : ''}`}>
                <div ref={sec1Ref}><Section1Top themeColor={themeColor} display={display} yPos={yPos}/></div>
                <div ref={sec2Ref}><Section2OurMission yPos={yPos}/></div>
                <div ref={sec3Ref}><Section3Service yPos={yPos}/></div>
                <div ref={sec4Ref}><Section4Team yPos={yPos}/></div>
                <div ref={sec5Ref}><Section5Recruiting yPos={yPos}/></div>
                <div ref={sec6Ref}><Section6News newsData={newsData} yPos={yPos}/></div>
                <div ref={sec7Ref}><Section7Company yPos={yPos}/></div>
                <div ref={sec8Ref}><Section8Contact yPos={yPos}/></div>

                <SideMenu nowIndex={nowPos} display={yPos > 100} pressFunc={(index) => {
                    if (index === 0) sec1Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 1) sec2Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 2) sec3Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 3) sec4Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 4) sec5Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 5) sec6Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 6) sec7Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    if (index === 7) sec8Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                }}/>
                <div>
                    <Footer pressFunc={(index) => {
                        if (index === 0) sec1Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 1) sec2Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 2) sec3Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 3) sec4Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 4) sec5Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 5) sec6Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 6) sec7Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                        if (index === 7) sec8Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                    }}/>
                </div>

                {/*</SmoothScroll>*/}
            </div>
            <SpMenu display={spMenuActive} pressFunc={(index) => {
                setSpMenuActive(false);
                if (index === 0) sec1Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 1) sec2Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 2) sec3Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 3) sec4Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 4) sec5Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 5) sec6Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 6) sec7Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
                if (index === 7) sec8Ref.current.scrollIntoView({behavior: "smooth", block: "start"});
            }}/>
            <Fab linkUrl='https://recruit.mento.co.jp/' display={yPos > 100}/>

        </>
    )
}

