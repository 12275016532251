// extracted by mini-css-extract-plugin
export var card = "style-module--card--WavPb";
export var cardImage = "style-module--cardImage--5rE+l";
export var cardLinkList = "style-module--cardLinkList--h0kIu";
export var cardName = "style-module--cardName--dak5B";
export var cardPosition = "style-module--cardPosition--Oh2+Y";
export var link = "style-module--link--dGmbO";
export var link__hover = "style-module--link__hover--ruvbG";
export var link__hoverImg = "style-module--link__hoverImg--u5kxx";
export var link__hoverText = "style-module--link__hoverText--mU7oW";
export var link__normal = "style-module--link__normal--f8zTe";
export var link__normalImg = "style-module--link__normalImg--bHrqh";
export var link__normalText = "style-module--link__normalText--BVblc";
export var list = "style-module--list--FRbRz";