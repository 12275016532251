import React, {useEffect, useRef, useState} from 'react';
import * as styles from './css/style.module.scss';
import {useViewportScroll} from "framer-motion";
import {spline} from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';

const colorPalette = [
    ['#0064BF', '#008DE3', '#B2E2E4'],
    ['#05ADDD', '#5CC8E1', '#B2E2E4'],
    ['#FFB328', '#FFCD70', '#FFE6B8'],
    ['#F2685F', '#F48F86', '#F6B6AD'],
    ['#0EA564', '#5CC395', '#A9E0C6'],
    ['#7D4698', '#A87BC1', '#D2B0EA'],
    ['#F37B22', '#F6A75A', '#F9D391'],
    ['#F35586', '#F891B4', '#FCCCE3']
]

export const SideMenu = (props) => {
    const {nowIndex, display, pressFunc} = props;
    // const {scrollY} = useViewportScroll();
    const ref = useRef(null);
    // useEffect(() => {
    //     scrollY.onChange((latest) => {
    //         console.log(latest)
    //         console.log(ref.current.scrollTop)
    //     });
    // }, []);

    // todo blob作る処理は共通化したい
    // const refPath = useRef(null);
    const [d, setD] = useState("")

    // let hueNoiseOffset = 0;
    let noiseStep = 0.005;

    const points = createPoints();
    const simplex = new SimplexNoise();
    const reqIdRef = React.useRef();

    const animate = () => {
        setD(spline(points, 1, true))

        // for every point...
        for (let i = 0; i < points.length; i++) {
            const point = points[i];

            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = map(nX, -1, 1, point.originX - 2, point.originX + 2);
            const y = map(nY, -1, 1, point.originY - 2, point.originY + 2);

            // update the point's current coordinates
            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }

        reqIdRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        animate();
        return () => cancelAnimationFrame(reqIdRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x, y) {
        return simplex.noise2D(x, y);
    }

    function createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 6;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 20;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 17 + Math.cos(theta) * rad;
            const y = 17 + Math.sin(theta) * rad;

            // store the point's position
            points.push({
                x: x,
                y: y,
                // we need to keep a reference to the point's original point for when we modulate the values later
                originX: x,
                originY: y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000
            });
        }

        return points;
    }

    return (<>
            <div className={`${styles.wrap__icon} ${display ? styles.show : ''} `}>
                <div className={styles.inner} ref={ref}>
                    <div className={styles.selectIcon} style={{
                        transform: `translateY(${(nowIndex * 34) + 18}px)`,
                        clipPath: ` url(#pcMenuIcon)`,
                        background: `radial-gradient(129.29% 129.11% at 5.19% 10.84%, rgba(255, 255, 255, 0.75) 0%, rgba(255,255,255,0.2) 41%, #00000000 100%) , ${colorPalette[nowIndex][0]}`,
                    }}>
                        <svg width="100%" height="100%" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
                            <clipPath id='pcMenuIcon'>
                                <path transform="scale(0.36, 0.36) translate(6 6)"
                                      d={d}/>
                            </clipPath>
                        </svg>
                    </div>
                </div>
            </div>
            <div className={`${styles.wrap} ${display ? styles.show : ''}`}>
                <div className={styles.inner} ref={ref}>
                    <button onClick={() => pressFunc(0)} className={`${nowIndex === 0 ? styles.current : ''}`}
                            text='TOP'>TOP
                    </button>
                    <button onClick={() => pressFunc(1)} className={`${nowIndex === 1 ? styles.current : ''}`}
                            text='OUR MISSION'>OUR MISSION
                    </button>
                    <button onClick={() => pressFunc(2)} className={`${nowIndex === 2 ? styles.current : ''}`}
                            text='SERVICE'>SERVICE
                    </button>
                    <button onClick={() => pressFunc(3)} className={`${nowIndex === 3 ? styles.current : ''}`}
                            text='TEAM'>TEAM
                    </button>
                    <button onClick={() => pressFunc(4)} className={`${nowIndex === 4 ? styles.current : ''}`}
                            text='RECRUITING'>RECRUITING
                    </button>
                    <button onClick={() => pressFunc(5)} className={`${nowIndex === 5 ? styles.current : ''}`}
                            text='NEWS'>NEWS
                    </button>
                    <button onClick={() => pressFunc(6)} className={`${nowIndex === 6 ? styles.current : ''}`}
                            text='COMPANY'>COMPANY
                    </button>
                    <button onClick={() => pressFunc(7)} className={`${nowIndex === 7 ? styles.current : ''}`}
                            text='CONTACT'>CONTACT
                    </button>
                </div>
            </div>
        </>
    )
}
