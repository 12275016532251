import React from 'react';
import * as styles from './css/style.module.scss';
import {SpTitle} from "../SpTitle";

export const Section7Company = (props) => {

    return <div className={styles.wrap}>
        <div className={styles.SpTitle}><SpTitle titleText={'COMPANY'} iconColor={['#F37B22','#F6A75A','#F9D391']} clipPathId={'sectionTitle'}/></div>
        <div className={styles.title}>
            COMPANY
        </div>
        <div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>社名</div>
                <div className={styles.list__text}>株式会社mento</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>所在地</div>
                <div className={styles.list__text}>東京都渋谷区渋谷2丁目11−5<br/>クロスオフィス渋谷メディオ7F</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>代表取締役</div>
                <div className={styles.list__text}>木村 憲仁</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>資本金</div>
                <div className={styles.list__text}>1億円</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>従業員数</div>
                <div className={styles.list__text}>正社員16名 業務委託40名</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>設立</div>
                <div className={styles.list__text}>2018年2月</div>
            </div>
            <div className={styles.list__row}>
                <div className={styles.list__title}>事業内容</div>
                <div className={styles.list__text}>管理職向けマネジメントコーチ「mento for Business」の開発・運営<br/>個人向けコーチングサービス「mento」の開発・運営</div>
            </div>
        </div>
    </div>
}
