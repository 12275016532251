import React, {useEffect, useState} from 'react';
// import {Container, Graphics, Sprite, Stage, useTick} from '@inlet/react-pixi';
import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';
// import * as PIXI from 'pixi.js';

export const MouseTracker = () => {
    const [d, setD] = useState("")

    let hueNoiseOffset = 0;
    let noiseStep = 0.005;

    const points = createPoints();
    const simplex = new SimplexNoise();
    const reqIdRef = React.useRef();

    const animate = () => {
        setD(spline(points, 1, true))

        // for every point...
        for (let i = 0; i < points.length; i++) {
            const point = points[i];

            // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
            const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
            // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
            const x = map(nX, -1, 1, point.originX - 15, point.originX + 15);
            const y = map(nY, -1, 1, point.originY - 15, point.originY + 15);

            // update the point's current coordinates
            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }

        reqIdRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        animate();
        return () => cancelAnimationFrame(reqIdRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function map(n, start1, end1, start2, end2) {
        return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x, y) {
        return simplex.noise2D(x, y);
    }

    function createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 6;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 150;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 100 + Math.cos(theta) * rad;
            const y = 100 + Math.sin(theta) * rad;

            // store the point's position
            points.push({
                x: x,
                y: y,
                // we need to keep a reference to the point's original point for when we modulate the values later
                originX: x,
                originY: y,
                // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000
            });
        }

        return points;
    }

    return <svg width="100" height="100" viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
        <clipPath id='clipFluid'>
            <path transform="scale(0.36, 0.36) translate(60 55)"
                  d={d}/>
        </clipPath>
    </svg>

}
// pixijsでの実装　ボツです
// export const MouseTracker_ = (props) => {
//
//     const Inner = () => {
//         const [ticker, setTicker] = useState(0)
//         const parkinson = 6
//         const radius = 51;
//         const draw = useCallback((g) => {
//             g.clear();
//             g.beginFill(0xffffff);
//
//             g.x = 60;
//             g.y = 60;
//
//             console.log(ticker)
//             console.log(parkinson)
//             let moveSin = Math.sin(ticker) * parkinson;
//             console.log(moveSin)
//             let moveCos = Math.cos(ticker) * parkinson;
//
//             g.moveTo(0, -radius);
//             g.quadraticCurveTo(radius, -radius, radius, moveSin);
//             g.quadraticCurveTo(radius - moveCos, radius + moveSin, 0 - moveCos, radius + moveSin);
//             g.quadraticCurveTo(-radius + moveCos, radius, -radius, 0 - moveSin);
//             g.quadraticCurveTo(-radius, -radius, 0, -radius);
//             g.endFill();
//             g.rotation = ticker / 10;
//
//         }, [ticker]);
//
//         const maskRef = useRef();
//
//         useTick(delta => {
//             setTicker(ticker + 0.1);
//         });
//
//         // function gradient(from, to) {
//         //     const c = document.createElement("canvas");
//         //     const ctx = c.getContext("2d");
//         //     const grd = ctx.createLinearGradient(0,0,100,100);
//         //     grd.addColorStop(0, from);
//         //     grd.addColorStop(1, to);
//         //     ctx.fillStyle = grd;
//         //     ctx.fillRect(0,0,100,100);
//         //     return new PIXI.Texture.from(c);
//         // }
//
//         return (
//                 <Container width={120} height={120}>
//                     <Graphics
//                         draw={draw}
//                         preventRedraw={true}
//                         ref={maskRef}
//                     />
//                 </Container>
//         )
//
//     }
//
//     // const Inner = () => {
//     //     const [x, setX] = React.useState(0);
//     //     const mask = React.useRef();
//     //     const i = React.useRef(0);
//     //
//     //     useTick(delta => {
//     //         i.current += 0.05 * delta;
//     //         setX(Math.cos(i.current) * 100);
//     //     });
//     //
//     //     return (
//     //         <Container position={[250, 250]}>
//     //             <Sprite
//     //                 image="https://s3-us-west-2.amazonaws.com/s.cdpn.io/693612/IaUrttj.png"
//     //                 scale={[4, 4]}
//     //                 anchor={0.5}
//     //                 mask={mask.current}
//     //             />
//     //             <Graphics
//     //                 x={x}
//     //                 preventRedraw={true}
//     //                 draw={g => {
//     //                     g.beginFill(0x000000);
//     //                     g.drawCircle(-25, -25, 50);
//     //                     g.endFill();
//     //                 }}
//     //                 ref={mask}
//     //             />
//     //         </Container>
//     //     );
//     // };
//
//     return (<Stage width={120} height={120}  style={{
//         transition: 'background 1s ease-out',
//         background: `radial-gradient(41.35% 46.34% at 67.03% 62.33%, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.5) 14.58%, rgba(255,255,255,0.2) 54.17%, #00000000 100%) , ${props.color} `,
//         transform: `translate3d(${props.left}px, ${props.top}px, 0px)`
//     }} options={{ backgroundColor: 0x000000, transparent: true}}>
//         <Inner />
//     </Stage>)
// }
